const yaMetrikaExtScriptUrl = "https://mc.yandex.ru/metrika/tag.js";

declare global {
    interface Window {
        yandexMetrikaCounter: any;
        yaCounters: any;
        Ya: {
            Metrika2: any;
        };
    }
}

export const loadScript = async (): Promise<boolean> => {
    return new Promise((resolve, reject) => {
        const el = document.querySelector(`script[src="${yaMetrikaExtScriptUrl}"]`);

        if (!el) {
            const screl = document.createElement("script");
            // screl.type = "text/javascript";
            // screl.async = true;
            // screl.defer = true;
            // screl.src = yaMetrikaExtScriptUrl;

            screl.setAttribute('src', yaMetrikaExtScriptUrl);

            document.head.appendChild(screl);

            screl.addEventListener("error", (err) => {
                console.log('error', {err})
                reject()
            });
            screl.addEventListener("abort", (err) => {
                console.log({err})
                reject()
            });
            screl.addEventListener("load", () => {
                screl.setAttribute("data-loaded", "true");
                resolve(true);
            });
        }

        if (!!el && el.hasAttribute("data-loaded")) {
            resolve(false);
        }
    });
};

export const installMetrica = async () => {
    try {
        if (!window.yaCounters) {
            window.yaCounters = {};
        }

        const isScriptLoaded = await loadScript();

        if (!isScriptLoaded) {
            return;
        }

        const init = {
            id: window.yandexMetrikaCounter,
            clickmap: true,
            trackLinks: true,
            accurateTrackBounce: true,
            webvisor: true,
            triggerEvent: true,
            childIframe: true,
            ecommerce: 'dataLayer'
        };

        const metrika = new window.Ya.Metrika2(init);

        if (!window.yaCounters) {
            window.yaCounters = {};
        }

        window.yaCounters[`${window.yandexMetrikaCounter}`] = metrika;
    } catch (e) {
        console.log(e)
    }
};

export const metrikaGoal = (value: string, params?: any) => {
    try {
        // @ts-ignore
        if (!window?.yaCounters || !window?.yaCounters[`${window.yandexMetrikaCounter}`]) {
            console.log("Yandex.Metrika not initialized.");

            return;
        }

        // @ts-ignore
        const id = window.yandexMetrikaCounter;

        console.info("Yandex.Metrika was founded.", "We use the counter number:", id);

        window.yaCounters[`${window.yandexMetrikaCounter}`].reachGoal(value, params)
    } catch (e) {
        console.error("Yandex.Metrika error:", e);
    }
};
